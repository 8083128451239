<template>
  <div class="all"></div>
</template>

<script>
export default {
  name: "EquipmentEmptyPanel"
};
</script>

<style scoped>
.all {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
}
</style>
